// src/hooks/useWebsocket.js

import { useEffect, useState } from "react";
import {
    websocket,
    initWebSocket,
    startRecording as startWebsocketRecording,
    stopRecording as stopWebsocketRecording,
    getAudioStream
} from "../utils/socket";

export default function useWebsocket() {
    const [isRecording, setIsRecording] = useState(false);
    const [transcription, setTranscription] = useState('');
    const [finalTranscription, setFinalTranscription] = useState('');
    const [audioStream, setAudioStream] = useState(null); // New state for audio stream
    const [error, setError] = useState(null); // Optional: To handle errors

    async function startRecording() {
        try {
            await startWebsocketRecording();
            setIsRecording(true);
            const stream = getAudioStream();
            setAudioStream(stream);
            console.log("socket stream => ", stream);
        } catch (err) {
            console.error("Failed to start recording:", err);
            setError(err);
        }
    }

    function stopRecording() {
        stopWebsocketRecording();
        setIsRecording(false);
        setAudioStream(null);
    }

    function handleTranscript(event) {
        const silence_bug_words = ['Tchau.', 'Obrigado.', 'Amém.', 'Boa Tarde.', 'Thank you for watching.', 'Team.', "Legenda por Sônia Ruberti"]
        const transcript_data = JSON.parse(event.data);
        const transcriptText = transcript_data['text'];
        
        if(!silence_bug_words.includes(transcriptText)){
            setTranscription(transcriptText);
            setFinalTranscription(prev => `${prev}\n - ${transcriptText}`);
        }
        
    }

    useEffect(() => {
        initWebSocket();
        websocket.onmessage = handleTranscript;

        // Cleanup on unmount
        return () => {
            if (websocket) {
                websocket.close();
            }
        };
    }, []);

    useEffect(() => {
        websocket.onmessage = handleTranscript;
    }, [transcription]);

    return {
        isRecording,
        transcription,
        finalTranscription,
        startRecording,
        stopRecording,
        audioStream, // Expose the audio stream
        error // Optional: Expose any errors
    };
}
