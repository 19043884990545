import React, { useEffect, useState } from "react";
import './main-page.scss';
import MainLayout from "../../layouts/main";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Divider from "../../components/Divider";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import KeyboardVoiceIcon from '@mui/icons-material/KeyboardVoice';
import CancelIcon from '@mui/icons-material/Cancel';
import Tooltip from '@mui/material/Tooltip';
import { formatDate } from "../../utils/fomatDate";
import apiBaseUrl from '../../utils/apiConfig';
import useWebsocket from "../../hooks/useWebsocket";
import { CircularProgress } from '@mui/material';
import AudioVisualizer from '../../components/AudioVisualizer';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import Rating from '@mui/material/Rating';

export default function MainPage() {
    const [micDisabled, setMicDisabled] = useState(true);
    const [age, setAge] = useState('');
    const [gender, setGender] = useState('');
    const [name, setName] = useState('');
    const [date, setDate] = useState(formatDate(new Date()));

    const [isPrinting, setIsPrinting] = useState(false);
    const { isRecording, transcription, finalTranscription, startRecording, stopRecording, audioStream, error } = useWebsocket();

    const [isFirstFetch, setIsFirstFetch] = useState(true);
    const [hpma, setHpma] = useState("");
    const [isda, setIsda] = useState("");
    const [alergias, setAlergias] = useState([]);
    const [conduta, setConduta] = useState("");
    const [exameFisico, setExameFisico] = useState("");
    const [medicamentosAdministrados, setMedicamentosAdministrados] = useState([]);
    const [medicamentosPrescritos, setMedicamentosPrescritos] = useState([]);
    const [queixaDuracao, setQueixaDuracao] = useState("");
    const [sintomas, setSintomas] = useState([]);
    const [updatedTranscription, setUpdatedTranscription] = useState();
    const [loading, setLoading] = useState(false);

    const [rating, setRating] = useState(0);
    const [ratingDisabled, setRatingDisabled] = useState(false);

    const handleRatingChange = async (event, newValue) => {
        setRating(newValue);
    
        // Monta o payload para enviar ao backend
        const payload = {
            nome: name,
            sexo: gender,
            idade: age,
            queixa_duracao: queixaDuracao,
            hpma,
            isda,
            exame_fisico: exameFisico,
            conduta,
            alergias: alergias.join(', '),
            medicamentos_administrados: medicamentosAdministrados.join(', '),
            medicamentos_prescritos: medicamentosPrescritos.join(', '),
            transcricao: updatedTranscription,
            nota_avaliacao: newValue,
            created_at: new Date().toISOString(), // caso deseje enviar a data do front
        };
    
        try {
            const response = await fetch('http://localhost:8000/evaluation', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            });
    
            if (!response.ok) {
                throw new Error(`Erro: ${response.statusText}`);
            }
    
            // Desabilita a avaliação depois do primeiro envio
            setRatingDisabled(true);
    
            alert('Avaliação enviada com sucesso!');
    
        } catch (error) {
            console.error('Erro ao enviar avaliação:', error);
            alert('Erro ao enviar avaliação!');
        }
    };
    

    // Função genérica para copiar qualquer texto
    const copyText = (text) => {
        navigator.clipboard.writeText(text);
    };

    const copyPatientInfoToClipboard = () => {
        const content = `Nome: ${name}\nIdade: ${age}\nGenero: ${gender}\nData: ${date}`;
        navigator.clipboard.writeText(content);
    };

    const copyToClipboard = (event) => {
        const cardElement = event.currentTarget.closest('.card');
        if (cardElement) {
            const content = cardElement.innerText || '';
            navigator.clipboard.writeText(content);
        }
    };

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const onInputChange = ({ target }) => {
        const name = target.name;
        const value = target.value;
        eval(`set${capitalizeFirstLetter(name)}`)(value);
    };

    const printDiagnose = () => {
        setIsPrinting(true);
        window.scroll({ top: 0, behavior: 'smooth' });
        setTimeout(() => {
            window.print();
        }, 1000);
    };

    const stopAndStructure = () => {
        structureData();
        stopRecording();
    };

    const structureData = () => {
        if (finalTranscription !== '') {
            setIsFirstFetch(false);
            setLoading(true);
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ transcription: finalTranscription, age: age, gender: gender })
            };

            fetch(`${apiBaseUrl}/structured_terms`, requestOptions)
                .then(response => response.json())
                .then(data => {
                    setHpma(data.HPMA || "");
                    setIsda(data.ISDA || "");
                    setAlergias(data.alergias || []);
                    setConduta(data.conduta || "");
                    setExameFisico(data.exame_fisico || "");
                    setMedicamentosAdministrados(data.medicamentos?.administrados || []);
                    setMedicamentosPrescritos(data.medicamentos?.prescritos || []);
                    setQueixaDuracao(data.queixa_duracao || "");
                    setSintomas(data.sintomas || []);
                    setUpdatedTranscription(finalTranscription);

                })
                .catch((err) => {
                    console.error(err);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    const renderCallButton = () => {
        if (micDisabled) {
            return (
                <Tooltip title="O preenchimento dos campos sexo e idade são obrigatórios para que o botão de gravação seja habilitado." placement="left" arrow>
                    <span>
                        <IconButton color="primary" disabled={micDisabled} className="call-icon-container">
                            <KeyboardVoiceIcon style={{ fontSize: "24px" }} />
                        </IconButton>
                    </span>
                </Tooltip>
            );
        }

        return (
            <IconButton color="primary" disabled={micDisabled} className="call-icon-container" onClick={startRecording}>
                <KeyboardVoiceIcon style={{ fontSize: "24px" }} />
            </IconButton>
        );
    };

    useEffect(() => {
        if (age && gender) {
            setMicDisabled(false);
            return;
        }
        setMicDisabled(true);
    }, [age, gender]);

    useEffect(() => {
        window.onafterprint = () => setIsPrinting(false);
    }, []);

    return (
        <MainLayout>
            <Grid container className="results-section" direction="column" gap={6}>

                {/** CARD - INFORMAÇÕES DO PACIENTE */}
                <Card className="card">
                    <CardContent className="content">
                        <Grid container className="title" alignItems="center" justifyContent="space-between">
                            <Typography variant="h5" component="h3">
                                <strong>Informações do Paciente</strong>
                                {(name || gender || age) && (
                                    <IconButton onClick={copyPatientInfoToClipboard}>
                                        <ContentCopyIcon />
                                    </IconButton>
                                )}
                            </Typography>
                            {isRecording ?
                                <IconButton color="primary" className="cancel-icon-container" onClick={stopAndStructure}>
                                    <CancelIcon style={{ fontSize: "24px" }} />
                                </IconButton>
                                :
                                renderCallButton()
                            }
                        </Grid>
                        <Box my={2} />
                        <Divider />
                        <Box py={2} />

                        <Grid container gap={2}>
                            {/** NOME */}
                            <Grid container item xs={12} sm={6} md={3} alignItems="flex-end">
                                <Box sx={{ width: '100%' }}>
                                    <TextField
                                        id="name"
                                        label="Nome"
                                        variant="standard"
                                        value={name}
                                        name="name"
                                        onChange={onInputChange}
                                        style={{ width: '100%' }}
                                    />
                                </Box>
                            </Grid>

                            {/** SEXO */}
                            <Grid container item xs={12} sm={6} md={3} alignItems="flex-end">
                                <FormControl variant="standard" style={{ width: '100%' }}>
                                    <InputLabel id="demo-simple-select-standard-label">Sexo</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={gender}
                                        name="gender"
                                        onChange={onInputChange}
                                    >
                                        <MenuItem value="female">Feminino</MenuItem>
                                        <MenuItem value="male">Masculino</MenuItem>
                                    </Select>
                                </FormControl>

                            </Grid>

                            {/** IDADE */}
                            <Grid container item xs={12} sm={6} md={3} alignItems="flex-end">
                                <Box sx={{ width: '100%' }}>
                                    <TextField
                                        id="age"
                                        label="Idade"
                                        variant="standard"
                                        type="number"
                                        value={age}
                                        name="age"
                                        onChange={onInputChange}
                                        style={{ width: '100%' }}
                                    />
                                </Box>
                            </Grid>

                            {/** DATA (SEM BOTÃO DE CÓPIA) */}
                            <Grid item xs={12} sm={6} md={3}>
                                <TextField
                                    id="date"
                                    label="Data"
                                    variant="standard"
                                    disabled
                                    value={date}
                                    style={{ width: '100%' }}
                                />
                            </Grid>
                        </Grid>

                        {isRecording && audioStream && (
                            <Box mt={4}>
                                <AudioVisualizer audioStream={audioStream} />
                            </Box>
                        )}
                        {error && (
                            <Box mt={2}>
                                <Typography variant="body2" color="error">
                                    Erro ao acessar o microfone: {error.message}
                                </Typography>
                            </Box>
                        )}
                    </CardContent>
                </Card>

                {/** CARD - SUMÁRIO DA CONSULTA */}
                <Card className="card">
                    <CardContent className="content">
                        <Grid container direction="column" gap={2}>
                            <Grid item className="title" justifyContent="space-between">
                                <Typography variant="h5" component="h3">
                                    <strong>Sumário da Consulta</strong>
                                    {(queixaDuracao || hpma || isda || exameFisico || conduta || alergias.length > 0 || medicamentosAdministrados.length > 0 || medicamentosPrescritos.length > 0) && (
                                        <IconButton onClick={(event) => copyToClipboard(event)}>
                                            <ContentCopyIcon />
                                        </IconButton>
                                    )}
                                </Typography>
                            </Grid>
                            <Divider />
                            <Box my={1} />

                            {!isFirstFetch ? (
                                loading ? (
                                    <Box display="flex" justifyContent="center" alignItems="center" width="100%">
                                        <CircularProgress />
                                    </Box>
                                ) : (
                                    <Grid container>

                                        {/** QUEIXA E DURAÇÃO */}
                                        {queixaDuracao && (
                                            <Grid item xs={12}>
                                                <Typography variant="h6" display="flex" alignItems="center">
                                                    <strong>Queixa e Duração</strong>
                                                    {/* Botão de copiar caso tenha conteúdo */}
                                                    <IconButton
                                                        onClick={() => copyText(queixaDuracao)}
                                                        style={{ marginLeft: 8 }}
                                                    >
                                                        <ContentCopyIcon />
                                                    </IconButton>
                                                </Typography>
                                                <Box my={1} />
                                                <Typography variant="body2">{queixaDuracao}</Typography>
                                                <Box my={5} />
                                            </Grid>
                                        )}

                                        {/** HPMA */}
                                        {hpma && (
                                            <Grid item xs={12}>
                                                <Typography variant="h6" display="flex" alignItems="center">
                                                    <strong>HPMA</strong>
                                                    <IconButton
                                                        onClick={() => copyText(hpma)}
                                                        style={{ marginLeft: 8 }}
                                                    >
                                                        <ContentCopyIcon />
                                                    </IconButton>
                                                </Typography>
                                                <Box my={1} />
                                                <Typography variant="body2">{hpma}</Typography>
                                                <Box my={5} />
                                            </Grid>
                                        )}

                                        {/** ISDA */}
                                        {isda && (
                                            <Grid item xs={12}>
                                                <Typography variant="h6" display="flex" alignItems="center">
                                                    <strong>ISDA</strong>
                                                    <IconButton
                                                        onClick={() => copyText(isda)}
                                                        style={{ marginLeft: 8 }}
                                                    >
                                                        <ContentCopyIcon />
                                                    </IconButton>
                                                </Typography>
                                                <Box my={1} />
                                                <Typography variant="body2">{isda}</Typography>
                                                <Box my={5} />
                                            </Grid>
                                        )}

                                        {/** EXAME FÍSICO */}
                                        {exameFisico && (
                                            <Grid item xs={12}>
                                                <Typography variant="h6" display="flex" alignItems="center">
                                                    <strong>Exame Físico</strong>
                                                    <IconButton
                                                        onClick={() => copyText(exameFisico)}
                                                        style={{ marginLeft: 8 }}
                                                    >
                                                        <ContentCopyIcon />
                                                    </IconButton>
                                                </Typography>
                                                <Box my={1} />
                                                <Typography variant="body2">{exameFisico}</Typography>
                                                <Box my={5} />
                                            </Grid>
                                        )}

                                        {/** CONDUTA */}
                                        {conduta && (
                                            <Grid item xs={12}>
                                                <Typography variant="h6" display="flex" alignItems="center">
                                                    <strong>Conduta</strong>
                                                    <IconButton
                                                        onClick={() => copyText(conduta)}
                                                        style={{ marginLeft: 8 }}
                                                    >
                                                        <ContentCopyIcon />
                                                    </IconButton>
                                                </Typography>
                                                <Box my={1} />
                                                <Typography variant="body2">{conduta}</Typography>
                                                <Box my={5} />
                                            </Grid>
                                        )}

                                        {/** ALERGIAS */}
                                        {alergias.length > 0 && (
                                            <Grid item xs={12}>
                                                <Typography variant="h6" display="flex" alignItems="center">
                                                    <strong>Alergias</strong>
                                                    <IconButton
                                                        onClick={() => copyText(alergias.join(', '))}
                                                        style={{ marginLeft: 8 }}
                                                    >
                                                        <ContentCopyIcon />
                                                    </IconButton>
                                                </Typography>
                                                <Box my={1} />
                                                {alergias.map((alergia, index) => (
                                                    <Typography key={index} variant="body2">
                                                        {alergia}
                                                    </Typography>
                                                ))}
                                                <Box my={5} />
                                            </Grid>
                                        )}

                                        {/** MEDICAMENTOS ADMINISTRADOS */}
                                        {medicamentosAdministrados.length > 0 && (
                                            <Grid item xs={12}>
                                                <Typography variant="h6" display="flex" alignItems="center">
                                                    <strong>Medicamentos Administrados</strong>
                                                    <IconButton
                                                        onClick={() => copyText(medicamentosAdministrados.join(', '))}
                                                        style={{ marginLeft: 8 }}
                                                    >
                                                        <ContentCopyIcon />
                                                    </IconButton>
                                                </Typography>
                                                <Box my={1} />
                                                {medicamentosAdministrados.map((medicamento, index) => (
                                                    <Typography key={index} variant="body2">{medicamento}</Typography>
                                                ))}
                                                <Box my={5} />
                                            </Grid>
                                        )}

                                        {/** MEDICAMENTOS PRESCRITOS */}
                                        {medicamentosPrescritos.length > 0 && (
                                            <Grid item xs={12}>
                                                <Typography variant="h6" display="flex" alignItems="center">
                                                    <strong>Medicamentos Prescritos</strong>
                                                    <IconButton
                                                        onClick={() => copyText(medicamentosPrescritos.join(', '))}
                                                        style={{ marginLeft: 8 }}
                                                    >
                                                        <ContentCopyIcon />
                                                    </IconButton>
                                                </Typography>
                                                <Box my={1} />
                                                {medicamentosPrescritos.map((medicamento, index) => (
                                                    <Typography key={index} variant="body2">{medicamento}</Typography>
                                                ))}
                                                <Box my={5} />
                                            </Grid>
                                        )}
                                    </Grid>
                                )
                            ) : null}
                        </Grid>
                    </CardContent>
                </Card>

                {updatedTranscription && (
                    <Card className="card">
                        <CardContent className="content">
                            <Grid container direction="column" gap={2}>
                                <Grid item className="title" justifyContent="space-between" style={{ width: '100%' }}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                        <Typography variant="h5" component="h3">
                                            <strong>Transcrição</strong>
                                            <IconButton onClick={(event) => copyToClipboard(event)}>
                                                <ContentCopyIcon />
                                            </IconButton>
                                        </Typography>

                                        <Grid>
                                            <Typography variant="subtitle1" sx={{ mb: 1 }}>
                                                Avaliação:
                                            </Typography>
                                            <Rating
                                                name="simple-controlled"
                                                value={rating}
                                                onChange={handleRatingChange}
                                                disabled={ratingDisabled}
                                            />
                                        </Grid>

                                    </div>
                                </Grid>
                                <Divider />
                                <Box my={1} />
                                <Typography variant="body2" style={{ maxWidth: '100%' }}>
                                    {updatedTranscription}
                                </Typography>
                            </Grid>
                        </CardContent>
                    </Card>
                )}

                <Grid container direction="column" gap={2} style={{ width: '100%', maxWidth: '1400px', margin: '0 auto' }}>
                    <Grid item>
                        {!isPrinting && (
                            <Button variant="contained" onClick={printDiagnose} fullWidth>
                                Imprimir Relatório
                            </Button>
                        )}
                    </Grid>

                </Grid>
            </Grid>
        </MainLayout>
    );
}
